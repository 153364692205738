import axios, { AxiosResponse } from "axios";
import { checkLogin } from "./firebase";

const localhostURL = 'http://localhost:8000'
const baseURL = process.env.REACT_APP_NODE_BASE_URL+"";

export async function createNewCustomTripAPI(
    payload:{
        truckload: string,
        start: string,
        end: string,
        route:any[],
        totalCapacity: number,
        filledCapacity?: number,
        date?: Date
    }
) {
    let authToken = '';
    await checkLogin().then((res:any)=>{authToken = res.stsTokenManager.accessToken})

    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${baseURL}/apsrtc/createCustomTrip`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer "+(authToken?authToken:'')
            },
            data: payload
        })
            .then((res) => {
                resolve({ response: res.data })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export async function createNewTripAPI(payload:{vehicle_id: string,date?: Date}) {
    let authToken = '';
    await checkLogin().then((res:any)=>{authToken = res.stsTokenManager.accessToken})

    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${baseURL}/apsrtc/createTrip`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer "+(authToken?authToken:'')
            },
            data: payload
        })
            .then((res) => {
                resolve({ response: res.data })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export async function getAllTripsAPI() {
    let authToken = '';
    await checkLogin().then((res:any)=>{authToken = res.stsTokenManager.accessToken})

    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${baseURL}/apsrtc/getTripsByDate`,
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer "+(authToken?authToken:'')
            },
            data: new Date()
        })
            .then((res) => {
                resolve({ response: res.data })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export async function assignTripAPI(payload:{tripID: string,orderID: string}) {
    let authToken = '';
    await checkLogin().then((res:any)=>{authToken = res.stsTokenManager.accessToken})

    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${baseURL}/apsrtc/assignOrderToTrip`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer "+(authToken?authToken:'')
            },
            data: payload
        })
            .then((res) => {
                resolve({ response: res.data })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}