import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllOrdersAPI, getSingleIntOrderAPI, updateIntOrderAPI } from 'app/api/orders';
import { RootState } from '../store';

export interface OrderState {
    allOrders: any[]
    fetching: boolean,
}

const initialState: OrderState = {
    allOrders: [],
    fetching: false,
}

export const getAllOrders = createAsyncThunk(
    'orders/getAllIntOrders',
    async () => {
        const response = await getAllOrdersAPI();
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);

export const getSingleIntOrder = createAsyncThunk(
    'orders/getSingleIntOrder',
    async (orderID:string) => {
        const response = await getSingleIntOrderAPI(orderID);
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);

export const updateIntOrder = createAsyncThunk(
    'orders/updateIntOrder',
    async ({payload, orderID}:{payload:any, orderID:string}) => {
        const response = await updateIntOrderAPI(payload,orderID);
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);

export const generalSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllOrders.pending, (state) => {
                state.fetching = true
            })
            .addCase(getAllOrders.fulfilled, (state, action) => {
                state.fetching = false
                const { data } = action.payload;
                state.allOrders = data;
            })
            .addCase(getAllOrders.rejected, (state) => {
                state.fetching = false
            })

            .addCase(getSingleIntOrder.pending, (state) => {
                state.fetching = true
            })
            .addCase(getSingleIntOrder.fulfilled, (state, action) => {
                state.fetching = false
                const { data } = action.payload;
                let prevOrders = state.allOrders
                for (let index = 0; index < prevOrders.length; index++) {
                    if(prevOrders[index]._id===data._id){
                        prevOrders[index] = data;
                        break;
                    }
                }
            })
            .addCase(getSingleIntOrder.rejected, (state) => {
                state.fetching = false
            })

            .addCase(updateIntOrder.pending, (state) => {
                state.fetching = true
            })
            .addCase(updateIntOrder.fulfilled, (state, action) => {
                state.fetching = false
                const { data } = action.payload;
                let prevOrders = state.allOrders
                for (let index = 0; index < prevOrders.length; index++) {
                    if(prevOrders[index]._id===data._id){
                        prevOrders[index] = data;
                        break;
                    }
                }
            })
            .addCase(updateIntOrder.rejected, (state) => {
                state.fetching = false
            })

    }
})


export const { } = generalSlice.actions

export const getAllOrdersState = (state: RootState) => state.orders.allOrders;
export const getOrdersFetching = (state: RootState) => state.orders.fetching

export default generalSlice.reducer;