import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { getAllApsrtcOrders, getApsrtcOrderDetails, updateApsrtcOrderDetails } from '../api/apsrtc';


interface OrderState {
    fetching: boolean;
    fetchingSingle: boolean;
    fetchingError: boolean;
    pickupOrders: any[],
    dropOrders: any[],
    orderDetails: any
}



const initialState: OrderState = {
    fetching: false,
    fetchingError: false,
    fetchingSingle: false,
    pickupOrders: [],
    dropOrders: [],
    orderDetails: {}
}

export const getAllPickupApsrtcOrdersAction = createAsyncThunk(
    'apsrtc-order/get-all-pickup-orders',
    async (queryParams: { order_type: string, filterOrderStatus: string, page?: number, limit?: number }, { rejectWithValue }) => {
        const response = await getAllApsrtcOrders(queryParams);
        // The value we return becomes the `fulfilled` action payload
        if (response.response.data.success) return response.response.data.data
        return rejectWithValue("There was an error")
    }
);


export const getAllDropApsrtcOrdersAction = createAsyncThunk(
    'apsrtc-order/get-all-drop-orders',
    async (queryParams: { order_type: string, filterOrderStatus: string, page?: number, limit?: number }, { rejectWithValue }) => {
        const response = await getAllApsrtcOrders(queryParams);
        // The value we return becomes the `fulfilled` action payload
        if (response.response.data.success) return response.response.data.data
        return rejectWithValue("There was an error")
    }
);

export const getApsrtcOrderDetailsAction = createAsyncThunk(
    'apsrtc-order/get-order-details',
    async (queryParams: { order_type: string, orderID: string }, { rejectWithValue }) => {
        const response = await getApsrtcOrderDetails(queryParams);
        // The value we return becomes the `fulfilled` action payload
        if (response.response.data.success) return response.response.data.data
        return rejectWithValue("There was an error")
    }
);

export const updateApsrtcPickupOrderDetailsAction = createAsyncThunk(
    'apsrtc-order/update-pickup-order',
    async (updateBody: { order_type: string, payload: any , shouldPop?: boolean}, { rejectWithValue }) => {
        const response = await updateApsrtcOrderDetails(updateBody);
        // The value we return becomes the `fulfilled` action payload
        if (response.response.data.success) return {...response.response.data.data, pop:updateBody.shouldPop?true:false}
        return rejectWithValue("There was an error")
    }
);

export const updateApsrtcDropOrderDetailsAction = createAsyncThunk(
    'apsrtc-order/update-drop-order',
    async (updateBody: { order_type: string, payload: any, shouldPop?: boolean }, { rejectWithValue }) => {
        const response = await updateApsrtcOrderDetails(updateBody);
        // The value we return becomes the `fulfilled` action payload
        if (response.response.data.success) return {...response.response.data.data, pop:updateBody.shouldPop?true:false}
        return rejectWithValue("There was an error")
    }
);

export const operatorSlice = createSlice({
    name: 'operator',
    initialState,
    reducers: {
        setApsrtcOrderData: (state, action) => {
            state.orderDetails = action.payload
        },

        updateApsrtcPickupOrder: (state, action) => {
            let tempOrders = state.pickupOrders
            for (let i = 0; i < tempOrders.length; i++) {
                const order = tempOrders[i];
                if (order.sm_orderid === action.payload.sm_orderid) {
                    if(action.payload.pop){
                        tempOrders.splice(i,1);
                    }else{
                        tempOrders[i] = action.payload;
                    }
                    break
                }
            }
            state.pickupOrders = tempOrders
            state.orderDetails = action.payload
        },
        updateApsrtcDropOrder: (state, action) => {
            let tempOrders = state.dropOrders
            for (let i = 0; i < tempOrders.length; i++) {
                const order = tempOrders[i];
                if (order.sm_orderid === action.payload.sm_orderid) {
                    if(action.payload.pop){
                        tempOrders.splice(i,1);
                    }else{
                        tempOrders[i] = action.payload;
                    }
                    break
                }
            }
            state.dropOrders = tempOrders
            state.orderDetails = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllPickupApsrtcOrdersAction.pending, (state) => {
                state.fetching = true;
            })
            .addCase(getAllPickupApsrtcOrdersAction.fulfilled, (state, action) => {
                state.fetching = false;
                state.pickupOrders = action.payload
            })
            .addCase(getAllPickupApsrtcOrdersAction.rejected, (state) => {
                state.fetching = false
                state.pickupOrders = []
            })

            .addCase(getAllDropApsrtcOrdersAction.pending, (state) => {
                state.fetching = true;
            })
            .addCase(getAllDropApsrtcOrdersAction.fulfilled, (state, action) => {
                state.fetching = false;
                state.dropOrders = action.payload
            })
            .addCase(getAllDropApsrtcOrdersAction.rejected, (state) => {
                state.fetching = false
                state.dropOrders = []
            })

            .addCase(getApsrtcOrderDetailsAction.pending, (state) => {
                state.fetchingSingle = true;
            })
            .addCase(getApsrtcOrderDetailsAction.fulfilled, (state, action) => {
                state.fetchingSingle = false;
                state.orderDetails = action.payload
            })
            .addCase(getApsrtcOrderDetailsAction.rejected, (state) => {
                state.fetchingSingle = false
            })

            .addCase(updateApsrtcPickupOrderDetailsAction.pending, (state) => {
                state.fetchingSingle = true;
            })
            .addCase(updateApsrtcPickupOrderDetailsAction.fulfilled, (state, action) => {
                state.fetchingSingle = false;
                let tempOrders = state.pickupOrders
                for (let i = 0; i < tempOrders.length; i++) {
                    const order = tempOrders[i];
                    if (order.sm_orderid === action.payload.sm_orderid) {
                        if(action.payload.pop){
                            tempOrders.splice(i,1);
                        }else{
                            tempOrders[i] = action.payload;
                        }
                        break
                    }
                }
                state.pickupOrders = tempOrders
                state.orderDetails = action.payload
            })
            .addCase(updateApsrtcPickupOrderDetailsAction.rejected, (state) => {
                state.fetchingSingle = false
            })

            .addCase(updateApsrtcDropOrderDetailsAction.pending, (state) => {
                state.fetchingSingle = true;
            })
            .addCase(updateApsrtcDropOrderDetailsAction.fulfilled, (state, action) => {
                state.fetchingSingle = false;
                let tempOrders = state.dropOrders
                for (let i = 0; i < tempOrders.length; i++) {
                    const order = tempOrders[i];
                    if (order.sm_orderid === action.payload.sm_orderid) {
                        if(action.payload.pop){
                            tempOrders.splice(i,1);
                        }else{
                            tempOrders[i] = action.payload;
                        }
                        break
                    }
                }
                state.dropOrders = tempOrders
                state.orderDetails = action.payload
            })
            .addCase(updateApsrtcDropOrderDetailsAction.rejected, (state) => {
                state.fetchingSingle = false
            })
    }
})

export const { setApsrtcOrderData, updateApsrtcPickupOrder, updateApsrtcDropOrder } = operatorSlice.actions

export const getAllApsrtcPickupOrdersState = (state: RootState) => state.operator.pickupOrders
export const getAllApsrtcDropOrdersState = (state: RootState) => state.operator.dropOrders
export const getApsrtcOrderDetailsState = (state: RootState) => state.operator.orderDetails
export const getOperatorFetchingState = (state: RootState) => state.operator.fetching
export const getOperatorSingleFetchingState = (state: RootState) => state.operator.fetchingSingle

export default operatorSlice.reducer;