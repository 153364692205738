import axios, { AxiosResponse } from "axios";
import { checkLogin } from "./firebase";

const localhostURL = 'http://localhost:8000'
const baseURL = process.env.REACT_APP_NODE_BASE_URL+"";

export async function getAllFleetAPI() {
    let authToken = '';
    await checkLogin().then((res:any)=>{authToken = res.stsTokenManager.accessToken})

    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${baseURL}/apsrtc/getFleet`,
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer "+(authToken?authToken:'')
            }
        })
            .then((res) => {
                resolve({ response: res.data })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}