import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import authReducer from './reducer/auth.reducer';
import apsrtcReducer from './reducer/apsrtc.reducer';
import ordersReducer from './reducer/orders.reducer';
import generalReducer from './reducer/general.reducer';
import fleetReducer from './reducer/fleet.reducer';
import operatorReducer from './reducer/operator.reducer';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    apsrtc: apsrtcReducer,
    general: generalReducer,
    orders: ordersReducer,
    fleet: fleetReducer,
    operator: operatorReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['order/createOrder/fulfilled']
      },
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
