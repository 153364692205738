import { Loader } from "@googlemaps/js-api-loader";
import loadable from '@loadable/component'
import { useEffect, useLayoutEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";

// import { getAuth } from './app/reducer/auth.reducer';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { getAuth, getUser, getUserData } from "./app/reducer/auth.reducer";
import { Icon } from "@chakra-ui/react";
import { RiEBike2Fill } from "react-icons/ri";
import { MdFiberNew, MdOutlineQrCode } from "react-icons/md";
import { FaBusAlt, FaBus, FaList, FaTruck, FaTruckLoading } from "react-icons/fa";
import { checkLogin } from "app/api/firebase";
import { unwrapResult } from "@reduxjs/toolkit";
import { getAllHubs } from "app/reducer/general.reducer";
import { getAllOrders } from "app/reducer/orders.reducer";
import { getAllFleet } from "app/reducer/fleet.reducer";
import { HiOutlineCurrencyRupee } from "react-icons/hi";


export const routes: RoutesType[] = [
  // {
  //   name: 'Book Local',
  //   path: '/book-local',
  //   icon: <Icon as={RiEBike2Fill} width='25px' height='25px' color='inherit' />
  // },
  // {
  //   name: 'Book Pan-India',
  //   path: '/book-panindia',
  //   icon: <Icon as={FaTruck} width='25px' height='25px' color='inherit' />
  // },
  {
    name: 'Book APSRTC',
    path: '/book-apsrtc',
    icon: <Icon as={FaBusAlt} width='25px' height='25px' color='inherit' />
  },
  // {
  // 	name: 'Divider',
  // 	path: '',
  // 	icon: <Icon as={FaList} width='20px' height='20px' color='inherit' />
  // },
  // {
  // 	name: 'Trip Assignment',
  // 	path: '/trip-assignment',
  // 	icon: <Icon as={FaTruckLoading} width='20px' height='20px' color='inherit' />
  // },
  // {
  // 	name: 'Vehicle Master List',
  // 	path: '/vehicles',
  // 	icon: <Icon as={FaBus} width='20px' height='20px' color='inherit' />
  // },
  {
    name: 'Divider',
    path: '',
    icon: <Icon as={FaList} width='20px' height='20px' color='inherit' />
  },
  {
    name: 'Orders',
    path: '/operator-pickups-and-deliveries',
    icon: <Icon as={FaList} width='20px' height='20px' color='inherit' />
  },
  // {
  //   name: 'ALL Orders',
  //   path: '/orders',
  //   icon: <Icon as={FaList} width='20px' height='20px' color='inherit' />
  // },
  // {
  //   name: 'APSRTC Orders',
  //   path: '/direct-orders',
  //   icon: <Icon as={FaList} width='20px' height='20px' color='inherit' />
  // },
  // {
  //   name: 'Local Orders',
  //   path: '/3pl-orders',
  //   icon: <Icon as={FaList} width='20px' height='20px' color='inherit' />
  // },
  // {
  //   name: 'Pan-India Orders',
  //   path: '/3pl-orders',
  //   icon: <Icon as={FaList} width='20px' height='20px' color='inherit' />
  // },
  // {
  //   name: 'Divider',
  //   path: '',
  //   icon: <Icon as={FaList} width='20px' height='20px' color='inherit' />
  // },
  // {
  //   name: 'Pricing',
  //   path: '/pricing',
  //   icon: <Icon as={HiOutlineCurrencyRupee} width='20px' height='20px' color='inherit' />
  // },
  // {
  // 	name: 'Divider',
  // 	path: '',
  // 	icon: <Icon as={FaList} width='20px' height='20px' color='inherit' />
  // },
  // {
  // 	name: 'Scan Checkpoint',
  // 	path: '/fleet-assignment',
  // 	icon: <Icon as={MdOutlineQrCode} width='20px' height='20px' color='inherit' />
  // }
];


export const Loading = () => {
  return <div style={{ height: '100vh', width: '100vw', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>loading...</div>;
};

const Auth = loadable(() => import("./pages/Auth"), { fallback: <Loading />, });

const Dashboard = loadable(() => import("./pages/Dashboard"), { fallback: <Loading />, });
const Orders = loadable(() => import("./pages/orders"), { fallback: <Loading />, });
const ApsrtcAllOrdersOrderList = loadable(() => import('./pages/apsrtc/AllOrdersPage'), { fallback: <Loading /> })

let APIKEY: string;
APIKEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY : ''

declare global {
  interface Window {
    gmapsLoaded: boolean;
    loader: Loader;
  }
}

if (!window.gmapsLoaded) {
  console.log('GMAP LOADED');
  const loader = new Loader({
    apiKey: APIKEY,
    version: "weekly",
    libraries: ["places"]
  });
  window.loader = loader;
  window.gmapsLoaded = true;
}

const ScrollToTopWrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
}

function RequireAuth({ children }: { children: JSX.Element }) {
  // const [showLoginForm, setShowLoginForm] = useState(true);

  const isAuth = useAppSelector(getAuth);
  // const navigate = useNavigate();
  let location = useLocation();
  // const { height, width } = useViewportSize();
  console.log('RED: ', location);

  if (isAuth !== true) {
    if (location.pathname && (location.pathname !== '/')) {
      return (<>
        <Navigate to={"/sign-in?redirect=" + location.pathname} state={{ from: location }} replace />
      </>)
    } else {
      return (<>
        <Navigate to={"/sign-in"} state={{ from: location }} replace />
      </>)
    }
  }

  return children;
}

export function Site() {
  const [ready, setReady] = useState<boolean>(false)
  const dispatch = useAppDispatch();
  const isAuth = useAppSelector(getAuth);
  const userData = useAppSelector(getUser);

  useEffect(() => {
    initSite();
  }, [])

  const initSite = async () => {

    await checkLogin().then((res: any) => {

      console.log("SAVED AUTH: ", res);
      dispatch(getUserData())
        .then(unwrapResult).then(() => {
          dispatch(getAllHubs());
          dispatch(getAllOrders());
          dispatch(getAllFleet());
          setReady(true)

        })

    }).finally(() => {

      setReady(true)
    })

  }


  return (
    <Router>
      {/* {onLanding && <div className="site-banner">
        Flat 40% off on hyperlocal orders*
        <span style={{position:'absolute', zoom:1.2, right:'20px', cursor:'pointer'}} onMouseDown={e=>setOnLanding(false)}><FontAwesomeIcon icon={faTimesCircle} /></span>
      </div>} */}
      <ScrollToTopWrapper>
        <main style={{ overflowX: 'hidden' }}>
          <div />
          {
            ready ?
              <Routes>
                <Route path="/sign-in" element={<Auth />} />
                <Route path="/" element={<RequireAuth><Dashboard routes={routes} /></RequireAuth>}>
                  <Route index element={<ApsrtcAllOrdersOrderList />} />

                  <Route path="/book-apsrtc" element={<Orders />} />
                  <Route path="/book-local" element={<Orders />} />
                  <Route path="/book-panindia" element={<Orders />} />
                  <Route path="/operator-pickups-and-deliveries" element={<ApsrtcAllOrdersOrderList />} />

                  <Route path="/orders" element={<Orders />} />
                  <Route path="/direct-orders" element={<Orders />} />
                  <Route path="/3pl-orders" element={<Orders />} />

                  <Route path="/vehicles" element={<Orders />} />
                  <Route path="/trip-assignment" element={<Orders />} />
                  <Route path="/pricing" element={<Orders />} />

                  <Route path="/accounts" element={<Orders />} />
                  <Route path="/profile" element={<Orders />} />
                </Route>
              </Routes>
              :
              <div className="loader-wrapper"><div className="loader-magenta" style={{ zIndex: 8 }} /></div>
          }
        </main>
      </ScrollToTopWrapper>
    </Router>
  )

}

