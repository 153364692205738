import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme/theme';
import './App.css';
import { Site } from './Site';

function App() {
  return (
      <div className="App">
        <ChakraProvider theme={theme}>
          <Site />
        </ChakraProvider>
      </div>
  );
}

export default App;
