import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getUserDataAPI, updateBankDetailsAPI, updateCompanyDetailsAPI, updateUserDataAPI } from 'app/api/auth';
import { RootState, AppThunk } from '../store';
export interface AuthState {
    fetching: boolean,
    ready: boolean,
    isAuth: boolean;
    user: any;
    pricingExport: any;
    pricingPackerMover: any;
    regions: any;
}

const initialState: AuthState = {
    fetching: false,
    isAuth: false,
    ready: false,
    user: undefined,
    pricingExport: undefined,
    pricingPackerMover: undefined,
    regions: undefined
}

export const getUserData = createAsyncThunk(
    'auth/getUserData',
    async () => {
        const response = await getUserDataAPI();
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);

export const updateUserData = createAsyncThunk(
    'auth/updateUserData',
    async (payload: any) => {
        const response = await updateUserDataAPI(payload);
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);

export const updateCompanyDetails = createAsyncThunk(
    'auth/updateCompanyDetails',
    async (payload: any) => {
        const response = await updateCompanyDetailsAPI(payload);
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);

export const updateBankDetails = createAsyncThunk(
    'auth/updateBankDetails',
    async (payload: any) => {
        const response = await updateBankDetailsAPI(payload);
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthBusy: (state,action) => {
            state.fetching = action.payload;
        },
        setPricingPackerMoverSlab: (state,action) => {
            const {activeSegment,zone,slabIndex,value} = action.payload;
            let temp = state.pricingPackerMover;
            if(value===''){
              temp[activeSegment].rates[zone].slab[slabIndex] = 100;
            }else if (parseInt(value) > 9999){
              temp[activeSegment].rates[zone].slab[slabIndex] = 9999;
            }else {
              temp[activeSegment].rates[zone].slab[slabIndex] = parseInt(value);
            }
            state.pricingPackerMover = temp
        },
        setPricingExportSlab: (state,action) => {
            const {activeSegment,zone,slabIndex,value} = action.payload;
            let temp = state.pricingExport;
            if(value===''){
              temp[activeSegment].rates[zone].slab[slabIndex] = 100;
            }else if (parseInt(value) > 9999){
              temp[activeSegment].rates[zone].slab[slabIndex] = 9999;
            }else {
              temp[activeSegment].rates[zone].slab[slabIndex] = parseInt(value);
            }
            state.pricingExport = temp
        },
        setPricingExportFlat: (state,action) => {
            const {activeSegment,zone,value} = action.payload;
            let temp = state.pricingExport;
            if(value===''){
              temp[activeSegment].rates[zone].flat = 100;
            }else if (parseInt(value) > 9999){
              temp[activeSegment].rates[zone].flat = 9999;
            }else {
              temp[activeSegment].rates[zone].flat = parseInt(value);
            }
            state.pricingExport = temp
        },
        setRegions: (state,action) => {
            state.regions = action.payload;
        },
        logOut: (state) => {
            state.user = {
                isAdmin: false,
                isBlocked: false,
                id: '',
                uid: '',
                username: '',
                email: '',
                phone: '',
                full_name: '',
                address: [],
                kyc_filename: '',
                default_places: undefined,
            };
            state.isAuth = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserData.pending, (state) => {
                state.fetching = true
            })
            .addCase(getUserData.fulfilled, (state, action) => {
                state.fetching = false
                state.isAuth = true;
                const { data } = action.payload;
                state.user = data;
                if(data.pricingExport){
                    state.pricingExport = data.pricingExport;
                }
                if(data.pricingPackerMover){
                    state.pricingPackerMover = data.pricingPackerMover;
                }
                state.regions = data.regions;
            })
            .addCase(getUserData.rejected, (state) => {
                state.fetching = false
            })

            .addCase(updateUserData.pending, (state) => {
                state.fetching = true
            })
            .addCase(updateUserData.fulfilled, (state, action) => {
                state.fetching = false
                const { data } = action.payload;
                state.user = data;
                if(data.pricingExport){
                    state.pricingExport = data.pricingExport;
                }
                if(data.pricingPackerMover){
                    state.pricingPackerMover = data.pricingPackerMover;
                }
                state.regions = data.regions;
            })
            .addCase(updateUserData.rejected, (state) => {
                state.fetching = false
            })

            .addCase(updateCompanyDetails.pending, (state) => {
                state.fetching = true
            })
            .addCase(updateCompanyDetails.fulfilled, (state, action) => {
                state.fetching = false
                const { data } = action.payload;
                state.user = data;
                if(data.pricingExport){
                    state.pricingExport = data.pricingExport;
                }
                if(data.pricingPackerMover){
                    state.pricingPackerMover = data.pricingPackerMover;
                }
                state.regions = data.regions;
            })
            .addCase(updateCompanyDetails.rejected, (state) => {
                state.fetching = false
            })

            .addCase(updateBankDetails.pending, (state) => {
                state.fetching = true
            })
            .addCase(updateBankDetails.fulfilled, (state, action) => {
                state.fetching = false
                const { data } = action.payload;
                state.user = data;
                if(data.pricingExport){
                    state.pricingExport = data.pricingExport;
                }
                if(data.pricingPackerMover){
                    state.pricingPackerMover = data.pricingPackerMover;
                }
                state.regions = data.regions;
            })
            .addCase(updateBankDetails.rejected, (state) => {
                state.fetching = false
            })
    }
})

export const { logOut, setAuthBusy, setPricingPackerMoverSlab, setPricingExportSlab, setPricingExportFlat, setRegions } = authSlice.actions;

export const getAuth = (state: RootState) => state.auth.isAuth;
export const getAuthBusy = (state: RootState) => state.auth.fetching;
export const getUser = (state: RootState) => state.auth.user;
export const getPricingExport = (state: RootState) => state.auth.pricingExport;
export const getPricingPackerMover = (state: RootState) => state.auth.pricingPackerMover;
export const getRegions = (state: RootState) => state.auth.regions;
export const getReady = (state: RootState) => state.auth.ready;

export default authSlice.reducer;