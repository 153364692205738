import axios, { AxiosResponse } from "axios";
import { checkLogin } from "./firebase";

const nodeBaseUrl = process.env.REACT_APP_NODE_BASE_URL


export async function getAllApsrtcRoutes() {

    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/apsrtc-book/get-cities`,
            method: "GET",
            headers: {
                Accept: "application/json"
            }
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });
    });
}

export async function calculateKMtoStation(lat, lng, stationName) {

    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/apsrtc/get-distance-from-hubs?`,
            method: "GET",
            headers: {
                Accept: "application/json"
            },
            params: {
                lat: lat,
                lng: lng,
                stationName: stationName
            }
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });
    });
}

export async function calculateApsrtcCost(data) {

    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/apsrtc-book/calculate`,
            method: "POST",
            headers: {
                Accept: "application/json"
            },
            data: data
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });
    });
}

export async function initApsrtcOrder(data) {
    let accessToken = '';
    try {
        const user: any = await checkLogin();
        accessToken = user ? user?.accessToken : ''
    } catch (e) {
        console.warn('auth warning: ', e);
    }
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/apsrtc-book/init-payment`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: 'Bearer ' + accessToken
            },
            data: data
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });
    });
}

export async function getAllApsrtcOrders(queryParams: { order_type: string, filterOrderStatus: string, page?: number, limit?: number }) {
    let accessToken = '';
    try {
        const user: any = await checkLogin();
        accessToken = user ? user?.accessToken : ''
    } catch (e) {
        console.warn('auth warning: ', e);
    }
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/saas/getAllApsrtcOrders`,
            method: "GET",
            headers: {
                Accept: "application/json",
                "saas-authorization": 'Bearer ' + accessToken
            },
            params: {
                order_type: queryParams.order_type,
                filterOrderStatus: queryParams.filterOrderStatus,
                page: queryParams.page,
                limit: queryParams.limit
            }
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });
    });
}

export async function getApsrtcOrderDetails(queryParams: { order_type: string, orderID: string }) {
    let accessToken = '';
    try {
        const user: any = await checkLogin();
        accessToken = user ? user?.accessToken : ''
    } catch (e) {
        console.warn('auth warning: ', e);
    }
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/saas/getSingleApsrtcOrder`,
            method: "GET",
            headers: {
                Accept: "application/json",
                "saas-authorization": 'Bearer ' + accessToken
            },
            params: {
                order_type: queryParams.order_type,
                orderID: queryParams.orderID
            }
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });
    });
}

export async function updateApsrtcOrderDetails(updateBody: { order_type: string, payload: any }) {
    let accessToken = '';
    try {
        const user: any = await checkLogin();
        accessToken = user ? user?.accessToken : ''
    } catch (e) {
        console.warn('auth warning: ', e);
    }
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${nodeBaseUrl}/saas/updateApsrtcOrder`,
            method: "PUT",
            headers: {
                Accept: "application/json",
                "saas-authorization": 'Bearer ' + accessToken
            },
            params: {
                order_type: updateBody.order_type,
            },
            data: updateBody.payload
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });
    });
}