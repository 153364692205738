import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut } from "firebase/auth";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTS_ID
};
  
// Initialize Firebase
// const analytics = getAnalytics(app);
const app = initializeApp(firebaseConfig);
// import { getAnalytics } from "firebase/analytics";

declare global {
    interface Window { 
        recaptchaVerifier: any; 
        confirmationResult: any;
    }
}

const auth = getAuth();

export function loginWithEmailPassword(email:string,password:string)  {

    return new Promise((resolve, reject) => {
  
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        resolve(user)
        // ...
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            reject();
        });
  
    })
    
}

export function signupWithEmailPassword(email:string,password:string)  {

    return new Promise((resolve, reject) => {
  
        createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        resolve(user)
        // ...
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            reject();
        });
  
    })
    
}

export function checkLogin() {

  return new Promise((resolve, reject) => {

    onAuthStateChanged(auth, async (user) => {
      
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        // const uid = user.uid;
        console.log("LOGGED IN USER", user)
        resolve(user)
        // ...
      } else {
        reject();
        // User is signed out
        // ...
      }
    });

  })
  
}

export function signout() {

  return new Promise<void>((resolve, reject) => {

    signOut(auth).then(() => {
      // Sign-out successful.
      resolve();
    }).catch((error) => {
      // An error happened.
      reject(error);
    });

  })
        
}