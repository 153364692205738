import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllFleetAPI } from 'app/api/fleet';
import { assignTripAPI, createNewTripAPI, getAllTripsAPI } from 'app/api/trip';
import { RootState } from '../store';

export interface OrderState {
    allFleet: any[],
    allTrips: any[],
    fetching: boolean,
}

const initialState: OrderState = {
    allFleet: [],
    allTrips: [],
    fetching: false,
}

export const getAllFleet = createAsyncThunk(
    'fleet/getAllFleet',
    async () => {
        const response = await getAllFleetAPI();
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);

export const getAllTrips = createAsyncThunk(
    'fleet/getAllTrips',
    async () => {
        const response = await getAllTripsAPI();
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);

export const createTrip = createAsyncThunk(
    'fleet/createTrip',
    async ({vehicle_id,date}:{vehicle_id: string,date?: Date}) => {
        const response = await createNewTripAPI({vehicle_id,date});
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);

export const assignOrderToTrip = createAsyncThunk(
    'fleet/assignOrderToTrip',
    async ({tripID,orderID}:{tripID: string,orderID: string}) => {
        const response = await assignTripAPI({tripID,orderID});
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);

export const fleetSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllFleet.pending, (state) => {
                state.fetching = true
            })
            .addCase(getAllFleet.fulfilled, (state, action) => {
                state.fetching = false
                const { data } = action.payload;
                state.allFleet = data;
            })
            .addCase(getAllFleet.rejected, (state) => {
                state.fetching = false
            })

            .addCase(getAllTrips.pending, (state) => {
                state.fetching = true
            })
            .addCase(getAllTrips.fulfilled, (state, action) => {
                state.fetching = false
                const { data } = action.payload;
                state.allTrips = data;
            })
            .addCase(getAllTrips.rejected, (state) => {
                state.fetching = false
            })

            .addCase(createTrip.pending, (state) => {
                state.fetching = true
            })
            .addCase(createTrip.fulfilled, (state, action) => {
                state.fetching = false
                const { data } = action.payload;
                let prevTrips = state.allTrips;
                prevTrips.push(data);
                state.allTrips = prevTrips;
            })
            .addCase(createTrip.rejected, (state) => {

            })

            .addCase(assignOrderToTrip.pending, (state) => {

            })
            .addCase(assignOrderToTrip.fulfilled, (state, action) => {

                const { updatedTrip } = action.payload.data;
                let prevTrips = state.allTrips;
                for (let index = 0; index < prevTrips.length; index++) {
                    if(prevTrips[index]._id===updatedTrip._id){
                        prevTrips[index] = updatedTrip;
                        break;
                    }
                }
                state.allTrips = prevTrips;
            })
            .addCase(assignOrderToTrip.rejected, (state) => {
                state.fetching = false
            })

    }
})


export const { } = fleetSlice.actions

export const getAllFleetState = (state: RootState) => state.fleet.allFleet;
export const getAllTripsState = (state: RootState) => state.fleet.allTrips;
export const getFleetFetching = (state: RootState) => state.fleet.fetching

export default fleetSlice.reducer;