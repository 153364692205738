import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllHubsAPI } from 'app/api/general';
import { RootState } from '../store';

export interface GeneralState {
    hubsList: any[],
    fetching: boolean,
}

const initialState: GeneralState = {
    hubsList: [],
    fetching: false,
}

export const getAllHubs = createAsyncThunk(
    'general/getAllHubs',
    async () => {
        const response = await getAllHubsAPI();
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);

export const generalSlice = createSlice({
    name: 'general',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
        .addCase(getAllHubs.pending, (state) => {
            state.fetching = true
        })
        .addCase(getAllHubs.fulfilled, (state, action) => {
            state.fetching = false
            state.hubsList = action.payload.data;
        })
        .addCase(getAllHubs.rejected, (state) => {
            state.fetching = false
        })
    }
})

export const { } = generalSlice.actions

export const getAllHubsState = (state: RootState) => state.general.hubsList;

export default generalSlice.reducer;